<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose-Score" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop-Score" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div
      class="dropdown"
      :class="{ 'dropdown--open': abreBloco }"
      v-if="Object.keys(this.resultados).length !== 0"
    >
      <HeaderBlocos :bloco="blocoScore" />
      <div class="body" :class="{ 'body--aberto': abreBloco }">
        <div class="row-info">
          <div class="info-col sb-4 sb-right">
            <div class="titles sb-4" :id="'BlocoPJ2-tipo'">Tipo de Empresa</div>
            <div class="results sb-top">
              <span
                v-for="(opcao, j) in [
                  'Ltda',
                  'SA',
                  'MEI',
                  'Eireli',
                  'EI',
                  'SS',
                  'Outro',
                ]"
                :key="j"
                class="tabText"
                :id="'BlocoPJ2-0-' + j"
                :class="{
                  selecionado: compararValores(
                    resultadoTratado.bk_201_004_des_tipo_empresa,
                    opcao
                  ),
                  'nao-selecionado': !compararValores(
                    resultadoTratado.bk_201_004_des_tipo_empresa,
                    opcao
                  ),
                }"
              >
                <p class="tabTexts">
                  {{ opcao }}
                </p>
                <span v-if="j < 6" style="color: black">|</span>
              </span>
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-anoFund'">Ano de Fundação</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_num_ano_fundacao }}
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-tempoFund'">Tempo Fundação</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_tmp_fundacao_anos }}
              <span
                style="margin-left: 3px"
                v-if="resultadoTratado.bk_201_004_tmp_fundacao_anos == 1"
              >
                ano
              </span>
              <span
                style="margin-left: 3px"
                v-if="
                  resultadoTratado.bk_201_004_tmp_fundacao_anos != 1 &&
                  resultadoTratado.bk_201_004_tmp_fundacao_anos != '-'
                "
              >
                anos
              </span>
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-primVinc'">Primeiro Vínculo</div>
            <div class="results sb-top">
              {{
                formatarData(resultadoTratado.bk_201_004_dt_primeiro_vinculo)
              }}
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-ultVinc'">Último Vínculo</div>
            <div class="results sb-top">
              {{ formatarData(resultadoTratado.bk_201_004_dt_ultimo_vinculo) }}
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-sn'">Simples Nacional</div>
            <div class="results sb-top">
              <Check
                :valor="
                  resultadoTratado.bk_201_004_flag_optante_simples_nacional
                "
              />
            </div>
          </div>
          <div class="info-col sb-3">
            <div class="titles" :id="'BlocoPJ2-faturamento'">
              Faturamento Presumido
            </div>
            <div class="results sb-top">
              {{
                formatarNumeroComPonto(
                  resultadoTratado.boxprpj002_faturamento_presumido
                )
              }}
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-totFil'">Total de Filiais</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_tqtd_total_filiais }}
            </div>
          </div>
          <div class="info-col sb-3 sb-right">
            <div class="titles" :id="'BlocoPJ2-ext'">Extensão</div>
            <div class="results sb-top">
              <span
                v-for="(opcao, j) in ['Local ', 'Regional', 'Nacional']"
                :key="j"
                class="tabText"
                :class="{
                  selecionado: compararValores(
                    resultadoTratado.bk_201_004_des_extensao_territorial,
                    opcao
                  ),
                  'nao-selecionado': !compararValores(
                    resultadoTratado.bk_201_004_des_extensao_territorial,
                    opcao
                  ),
                }"
                :id="'BlocoPJ2-8-' + j"
              >
                <p class="tabTexts">
                  {{ opcao }}
                </p>
                <span v-if="j < 2" style="color: black">|</span>
              </span>
            </div>
          </div>
          <div class="info-col sb-3 sb-right">
            <div class="titles" :id="'BlocoPJ2-porte'">Porte</div>
            <div class="results sb-top">
              <span
                v-for="(opcao, j) in ['MEI', 'ME ', 'EPP', 'Médio+']"
                :key="j"
                class="tabText"
                :class="{
                  selecionado: compararValores(
                    resultadoTratado.bk_201_004_des_porte,
                    opcao
                  ),
                  'nao-selecionado': !compararValores(
                    resultadoTratado.bk_201_004_des_porte,
                    opcao
                  ),
                }"
                :id="'BlocoPJ2-9-' + j"
              >
                <p class="tabTexts">
                  {{ opcao }}
                </p>
                <span v-if="j < 3" style="color: black">|</span>
              </span>
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-qtdSoc'">Qtde de Sócios</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_qtd_socios }}
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-altQsa'">Alteração QSA</div>
            <div class="results sb-top">
              <Check :valor="resultadoTratado.bk_201_004_flag_alteracao_qsa" />
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-recQsa'">Recência QSA</div>
            <div class="results sb-top">
              {{ formatarData(resultadoTratado.bk_201_004_dt_recencia_qsa) }}
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-recStatus'">
              Recência Status Ativa
            </div>
            <div class="results sb-top">
              {{
                formatarData(
                  resultadoTratado.bk_201_004_dt_recencia_status_ativa
                )
              }}
            </div>
          </div>
          <div class="info-col sb-1">
            <div class="titles" :id="'BlocoPJ2-origExt'">
              Origem no Exterior
            </div>
            <div class="results sb-top">
              <Check
                :valor="resultadoTratado.bk_201_004_flag_origem_exterior"
              />
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="info-col sb-2 sb-right">
            <div class="titles">Deatalhamentos</div>
            <div class="results sb-top" :id="'BlocoPJ2-situacaoCad'">
              Situação Cadastral
            </div>
            <div class="results sb-top" :id="'BlocoPJ2-situacaoMotivo'">
              Motivo Situação
            </div>
            <div class="results sb-top" :id="'BlocoPJ2-situacaoEsp'">
              Situação Especial
            </div>
          </div>
          <div class="info-col sb-6 sb-right">
            <div class="titles" :id="'BlocoPJ2-descritivo'">
              Descritivo da Situação
            </div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_des_situacao_cadastral }}
            </div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_des_motivo_situacao }}
            </div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_des_situacao_especial }}
            </div>
          </div>
          <div class="info-col sb-2 sb-right">
            <div class="titles" :id="'BlocoPJ2-dataSituacao'">
              Data da Situação
            </div>
            <div class="results sb-top">
              {{
                formatarData(resultadoTratado.bk_201_004_dt_situacao_cadastral)
              }}
            </div>
            <div class="results sb-top">
              {{ formatarData(resultadoTratado.bk_201_004_dt_motivo_situacao) }}
            </div>
            <div class="results sb-top">
              {{
                formatarData(resultadoTratado.bk_201_004_dt_situacao_especial)
              }}
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-tempoSituacao'">
              Tempo Situação
            </div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_tmp_situcao_cadastral_anos }}
              <span
                style="margin-left: 3px"
                v-if="
                  resultadoTratado.bk_201_004_tmp_situcao_cadastral_anos == 1
                "
              >
                ano
              </span>
              <span
                style="margin-left: 3px"
                v-if="
                  resultadoTratado.bk_201_004_tmp_situcao_cadastral_anos != 1 &&
                  resultadoTratado.bk_201_004_tmp_situcao_cadastral_anos != '-'
                "
              >
                anos
              </span>
            </div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_tmp_motivo_situacao_anos }}
              <span
                style="margin-left: 3px"
                v-if="resultadoTratado.bk_201_004_tmp_motivo_situacao_anos == 1"
              >
                ano
              </span>
              <span
                style="margin-left: 3px"
                v-if="
                  resultadoTratado.bk_201_004_tmp_motivo_situacao_anos != 1 &&
                  resultadoTratado.bk_201_004_tmp_motivo_situacao_anos != '-'
                "
              >
                anos
              </span>
            </div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_tmp_situacao_especial_anos }}
              <span
                style="margin-left: 3px"
                v-if="
                  resultadoTratado.bk_201_004_tmp_situacao_especial_anos == 1
                "
              >
                ano
              </span>
              <span
                style="margin-left: 3px"
                v-if="
                  resultadoTratado.bk_201_004_tmp_situacao_especial_anos != 1 &&
                  resultadoTratado.bk_201_004_tmp_situacao_especial_anos != '-'
                "
              >
                anos
              </span>
            </div>
          </div>
          <div class="info-col sb-1">
            <div class="titles" :id="'BlocoPJ2-risco'">Alerta Risco</div>
            <div class="results sb-top">
              <Check
                :valor="
                  resultadoTratado.bk_201_004_flag_alerta_risco_situacao_cadastral
                "
              />
            </div>
            <div class="results sb-top">
              <Check
                :valor="
                  resultadoTratado.bk_201_004_flag_alerta_risco_motivo_situacao
                "
              />
            </div>
            <div class="results sb-top">
              <Check
                :valor="
                  resultadoTratado.bk_201_004_flag_alerta_risco_situacao_especial
                "
              />
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="info-col sb-2 sb-right">
            <div class="titles" :id="'BlocoPJ2-cep-m'">CEP da Matriz</div>
            <div class="results sb-top">
              {{ formatarCEP(resultadoTratado.bk_201_004_num_cep_matriz) }}
            </div>
          </div>
          <div class="info-col sb-6 sb-right">
            <div class="titles" :id="'BlocoPJ2-rua-m'">Logradouro</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_des_logradouro_matriz }}
            </div>
          </div>
          <div class="info-col sb-3 sb-right">
            <div class="titles" :id="'BlocoPJ2-mun-m'">Município</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_des_municipio_matriz }}
            </div>
          </div>
          <div class="info-col sb-1">
            <div class="titles" :id="'BlocoPJ2-uf-m'">UF</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_des_uf_matriz }}
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="info-col sb-2 sb-right">
            <div
              class="titles"
              style="padding-inline: 30px"
              :id="'BlocoPJ2-tipoCep'"
            >
              Precisão do CEP da Matriz
            </div>
            <div class="results sb-top">
              <span
                v-for="(opcao, j) in ['Único', 'Parcial', 'Múltiplo']"
                :key="j"
                class="tabText"
                :class="{
                  selecionado: compararValores(
                    resultadoTratado.bk_201_004_precisao_cep_matriz,
                    opcao
                  ),
                  'nao-selecionado': !compararValores(
                    resultadoTratado.bk_201_004_precisao_cep_matriz,
                    opcao
                  ),
                }"
                :id="'BlocoPJ2-26-' + j"
              >
                <p class="tabTexts">
                  {{ opcao }}
                </p>
                <span v-if="j < 2" style="color: black">|</span>
              </span>
            </div>
          </div>

          <div class="info-col sb-9 sb-right">
            <div class="titles" :id="'BlocoPJ2-classCep'">
              Classificação do CEP da Matriz
            </div>
            <div class="results sb-top">
              <span
                v-for="(opcao, j) in [
                  'Padrão',
                  'Especial',
                  'Promocional',
                  'Comunitário',
                  'Correios',
                ]"
                :key="j"
                class="tabText"
                :class="{
                  selecionado: compararValores(
                    resultadoTratado.bk_201_004_classe_cep_matriz,
                    opcao
                  ),
                  'nao-selecionado': !compararValores(
                    resultadoTratado.bk_201_004_classe_cep_matriz,
                    opcao
                  ),
                }"
                :id="'BlocoPJ2-27-' + j"
              >
                <p class="tabTexts">
                  {{ opcao }}
                </p>
                <span v-if="j < 4" style="color: black">|</span>
              </span>
            </div>
          </div>
          <div class="info-col sb-1">
            <div class="titles" :id="'BlocoPJ2-qsaFamil'">
              Viés QSA Familiar
            </div>
            <div class="results sb-top">
              <Check
                :valor="resultadoTratado.bk_201_004_flag_qsa_vies_familiar"
              />
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="info-col sb-2 sb-right">
            <div class="titles" :id="'BlocoPJ2-concCnae'">Concent. CNAE</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_qtd_concentracao_cnae }}
            </div>
          </div>
          <div class="info-col sb-2 sb-right">
            <div
              class="titles"
              style="padding: 1px 25px"
              :id="'BlocoPJ2-distCnae'"
            >
              Distância ao Polo do CNAE
            </div>
            <div class="results sb-top">
              <span
                v-if="resultadoTratado.bk_201_004_distancia_polo_cnae != '-'"
              >
                {{ resultadoTratado.bk_201_004_distancia_polo_cnae.toFixed(1) }}
              </span>
              <span v-else>
                {{ resultadoTratado.bk_201_004_distancia_polo_cnae }}
              </span>
              <span
                style="margin-left: 3px"
                v-if="resultadoTratado.bk_201_004_distancia_polo_cnae > 0"
              >
                km
              </span>
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-concCnaePolo'">
              Concent. CNAE Polo
            </div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_qtd_concentracao_polo_cnae }}
            </div>
          </div>
          <div class="info-col sb-4 sb-right">
            <div class="titles" :id="'BlocoPJ2-munPolo'">Município do Polo</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_des_municipio_polo_cnae }}
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-ufPolo'">UF do Polo</div>
            <div class="results sb-top">
              {{ resultadoTratado.bk_201_004_des_uf_polo_cnae }}
            </div>
          </div>
          <div class="info-col sb-1 sb-right">
            <div class="titles" :id="'BlocoPJ2-matchUfPolo'">
              Match c/ UF Polo
            </div>
            <div class="results sb-top">
              <Check
                :valor="
                  resultadoTratado.bk_201_004_flag_match_uf_polo_cnae_matriz
                "
              />
            </div>
          </div>
          <div class="info-col sb-1">
            <div class="titles" :id="'BlocoPJ2-matchRegPolo'">
              Match c/ Região Polo
            </div>
            <div class="results sb-top">
              <Check
                :valor="
                  resultadoTratado.bk_201_004_flag_match_regiao_polo_cnae_matriz
                "
              />
            </div>
          </div>
        </div>
        <div class="row-info">
          <BlocoInfoPJSocios
            :resultados="resultadoTratado"
            :abreBloco="abreBloco"
            :exibirTootipsConsultas="exibirTootipsConsultas"
            :gerandoPdf="gerandoPdf"
          />
        </div>
        <div class="row-info" style="margin-top: 30px">
          <EmpresaMapa
            :codigo_agrupador="codigo_agrupador"
            :abreBloco="abreBloco"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
        </div>
      </div>
    </div>
    <div v-for="(tt, i) in tooltipsInfoPj2" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBoxPJ } from "@/stores/superbox-alertas-pj.js";
import { mapWritableState, mapActions } from "pinia";
import HeaderBlocos from "@/components/Modelos/HeaderBlocos.vue";
import BlocoInfoPJSocios from "@/components/Modelos/BlocoInfoPJSocios.vue";
import EmpresaMapa from "@/components/Modelos/EmpresaMapa.vue";
import Check from "@/components/SuperBox/Check.vue";
import listaTooltipsModelos from "@/helpers/lista_tooltips_modelos.json";

export default {
  name: "BlocoInfoPJ2",

  components: { HeaderBlocos, BlocoInfoPJSocios, EmpresaMapa, Check },

  props: {
    listaBlocos: Array,
    // valorCPF: String,
    resultados: Object,
    modeloSelecionado: Object,
    codigo_agrupador: String,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,
      valor: "-",
    };
  },

  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaAlertas();
      }
    },
  },

  methods: {
    atualizaAlertas() {
      if (Object.keys(this.resultados).length !== 0) {
        for (let key in this.resultados) {
          if (
            key.startsWith("alertas_gerais_") &&
            this.resultados[key] === true
          ) {
            const idAlerta = key.replace("alertas_gerais_", "");
            this.ativaAlerta(idAlerta);
          }
        }
      }
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    formatarCEP(numero) {
      if (numero != "-") {
        numero = String(numero);
        let cepLimpo = numero.replace(/\D/g, "");

        if (cepLimpo.length !== 8) {
          return numero;
        }

        return numero.replace(/^(\d{5})(\d{3})$/, "$1-$2");
      } else {
        return numero;
      }
    },

    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    compararValores(str1, str2) {
      const removerAcentos = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      const prepararString = (str) => {
        return str.trim().toLowerCase();
      };

      return (
        prepararString(removerAcentos(str1)) ===
        prepararString(removerAcentos(str2))
      );
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 2);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBoxPJ, ["ativaAlerta"]),
  },

  created() {},

  computed: {
    resultadoTratado() {
      const objetoCopiado = { ...this.resultados };

      for (const chave in objetoCopiado) {
        if (
          objetoCopiado[chave] === null &&
          !chave.includes("flag") &&
          !chave.includes("_ind_")
        ) {
          objetoCopiado[chave] = "-";
        }
      }
      return objetoCopiado;
    },

    blocoScore() {
      return this.listaBlocos.find(
        (bloco) => bloco.descricao === "Detalhamentos Cadastrais da Matriz"
      );
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    tooltipsInfoPj2() {
      return listaTooltipsModelos.bloco_info_pj_2;
    },

    // ...mapWritableState(blocosSuperBoxStore, {
    //   openBlocos: "openBlocos",
    // }),
  },

  mounted() {
    this.atualizaAlertas();
  },
};
</script>
<style lang="scss" scoped>
.body {
  align-items: center;
}

.row-info {
  width: 100%;
  margin-top: 5px;
  margin-left: 0px;
  display: flex;
  max-width: 950px;
  justify-content: center;
  cursor: default;
}

.info-col {
  padding: 0;
  display: flex;
  flex-direction: column;
  line-height: 16px;
}

.tabText {
  display: flex;
  margin: 0 !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-break: normal;

  .tabTexts {
    padding: 0 5px;
    margin: 0 !important;
  }
}

.titles {
  padding: 1px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  height: 40px;
  text-align: center;

  @include flex-center(column);
}

.results {
  height: 40px;
  text-align: center;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px 3px;
  word-break: break-word;
  line-height: 14px;
  // flex: 1;
}

.selecionado {
  color: #68a490;
  margin-inline: 3px;
  font-weight: 600;
}
.nao-selecionado {
  color: #a6a6a6;
  margin-inline: 3px;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
