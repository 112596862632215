var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linhas"},[_c('div',{staticClass:"principal"},[_c('div',{staticClass:"results titulo sb-1 sb-right sb-top",class:{
        desativado: !_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-',
      }},[_vm._v(" Sócio PJ "+_vm._s(_vm.indexPj + 1)+" ")]),_c('div',{staticClass:"results sb-2 sb-right sb-top",class:{
        'borda-desativada': !_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-',
      }},[(!_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-')?_c('span',[_vm._v(" Sem informações ")]):_c('span',{staticClass:"text-send"},[_vm._v(" "+_vm._s(_vm.formatarCNPJ(_vm.valoresSociosPJ.cnpj))+" "),(
            !_vm.telaHistorico &&
            !_vm.valoresSociosPJ.cnpj.includes('*') &&
            _vm.valoresSociosPJ.cnpj != '-' &&
            !_vm.gerandoPdf
          )?_c('span',{staticClass:"material-symbols-outlined icon-send",on:{"click":function($event){return _vm.selecionaConsultaAdicional(_vm.valoresSociosPJ.cnpj)}}},[_vm._v(" send ")]):_vm._e()])]),_c('div',{staticClass:"results sb-3 sb-right sb-top",class:{
        'borda-desativada': !_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-',
      }},[_vm._v(" "+_vm._s(_vm.valoresSociosPJ.cnaePrinc)+" ")]),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
        'borda-desativada': !_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-',
      }},[_vm._v(" "+_vm._s(_vm.valoresSociosPJ.tempoVinc)+" "),(_vm.valoresSociosPJ.tempoVinc == 1)?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" ano ")]):_vm._e(),(_vm.valoresSociosPJ.tempoVinc != 1 && _vm.valoresSociosPJ.tempoVinc != '-')?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" anos ")]):_vm._e()]),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
        'borda-desativada': !_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-',
      }},[_vm._v(" "+_vm._s(_vm.valoresSociosPJ.tempoFund)+" "),(_vm.valoresSociosPJ.tempoFund == 1)?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" ano ")]):_vm._e(),(_vm.valoresSociosPJ.tempoFund != 1 && _vm.valoresSociosPJ.tempoFund != '-')?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" anos ")]):_vm._e()]),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
        'borda-desativada': !_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-',
      },attrs:{"id":'sociopj-tipo-' + _vm.indexPj}},[_vm._v(" "+_vm._s(_vm.naturezaJuridicaTratada)+" ")]),(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'sociopj-tipo-' + _vm.indexPj,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" "+_vm._s(_vm.valoresSociosPJ.tipo)+" ")]):_vm._e(),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
        'borda-desativada': !_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-',
      },attrs:{"id":'sociopj-capital-' + _vm.indexPj}},[_vm._v(" "+_vm._s(_vm.capitalFormatado(_vm.valoresSociosPJ.capSoc))+" ")]),(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'sociopj-capital-' + _vm.indexPj,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" "+_vm._s(_vm.formatarNumeroComPonto(_vm.valoresSociosPJ.capSoc))+" ")]):_vm._e(),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
        'borda-desativada': !_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-',
      }},[_c('Check',{attrs:{"valor":_vm.valoresSociosPJ.alerta}})],1),_c('div',{staticClass:"arrowBox",class:{
        'btn-disabled': !_vm.valoresSociosPJ.cnpj || _vm.valoresSociosPJ.cnpj == '-',
      },on:{"click":function($event){return _vm.alternarDetalhes(_vm.indexPj)}}},[(!_vm.arrowControlPj)?_c('span',{staticClass:"material-symbols-outlined icon-arrow"},[_vm._v(" expand_circle_down ")]):_c('span',{staticClass:"material-symbols-outlined icon-arrow"},[_vm._v(" expand_circle_up ")])])],1),(_vm.arrowControlPj)?_c('div',{staticClass:"secundario"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"cabec sb-2 sb-right sb-top",attrs:{"id":'BlocoInfoPJSociosPjLinhas-0'}},[_vm._v(" Razão Social ")]),_c('div',{staticClass:"results sb-8 sb-right sb-top"},[_vm._v(" "+_vm._s(_vm.formatarNome(_vm.valoresSociosPJ.razao))+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"cabec sb-2 sb-right sb-top",attrs:{"id":'BlocoInfoPJSociosPjLinhas-1'}},[_vm._v(" Local ")]),_c('div',{staticClass:"results sb-8 sb-right sb-top"},[_vm._v(" "+_vm._s(_vm.valoresSociosPJ.local)+" ")])])]):_vm._e(),_vm._l((_vm.tooltipsInfoPjSociosLinhasFiltrado),function(tt,i){return _c('div',{key:i},[(_vm.abreBloco && _vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }