<template>
  <div class="linhas">
    <div class="principal">
      <div
        class="results titulo sb-1 sb-right sb-top"
        :class="{
          desativado: !valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-',
        }"
      >
        Sócio PJ {{ indexPj + 1 }}
      </div>
      <div
        class="results sb-2 sb-right sb-top"
        :class="{
          'borda-desativada': !valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-',
        }"
      >
        <span v-if="!valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-'">
          Sem informações
        </span>
        <span v-else class="text-send">
          {{ formatarCNPJ(valoresSociosPJ.cnpj) }}
          <span
            v-if="
              !telaHistorico &&
              !valoresSociosPJ.cnpj.includes('*') &&
              valoresSociosPJ.cnpj != '-' &&
              !gerandoPdf
            "
            class="material-symbols-outlined icon-send"
            @click="selecionaConsultaAdicional(valoresSociosPJ.cnpj)"
          >
            send
          </span>
        </span>
      </div>
      <div
        class="results sb-3 sb-right sb-top"
        :class="{
          'borda-desativada': !valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-',
        }"
      >
        {{ valoresSociosPJ.cnaePrinc }}
      </div>
      <div
        class="results sb-1 sb-right sb-top"
        :class="{
          'borda-desativada': !valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-',
        }"
      >
        {{ valoresSociosPJ.tempoVinc }}
        <span style="margin-left: 3px" v-if="valoresSociosPJ.tempoVinc == 1"> ano </span>
        <span
          style="margin-left: 3px"
          v-if="valoresSociosPJ.tempoVinc != 1 && valoresSociosPJ.tempoVinc != '-'"
        >
          anos
        </span>
      </div>
      <div
        class="results sb-1 sb-right sb-top"
        :class="{
          'borda-desativada': !valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-',
        }"
      >
        {{ valoresSociosPJ.tempoFund }}
        <span style="margin-left: 3px" v-if="valoresSociosPJ.tempoFund == 1"> ano </span>
        <span
          style="margin-left: 3px"
          v-if="valoresSociosPJ.tempoFund != 1 && valoresSociosPJ.tempoFund != '-'"
        >
          anos
        </span>
      </div>
      <div
        class="results sb-1 sb-right sb-top"
        :class="{
          'borda-desativada': !valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-',
        }"
        :id="'sociopj-tipo-' + indexPj"
      >
        {{ naturezaJuridicaTratada }}
      </div>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'sociopj-tipo-' + indexPj"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        {{ valoresSociosPJ.tipo }}
      </b-tooltip>
      <div
        class="results sb-1 sb-right sb-top"
        :class="{
          'borda-desativada': !valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-',
        }"
        :id="'sociopj-capital-' + indexPj"
      >
        {{ capitalFormatado(valoresSociosPJ.capSoc) }}
      </div>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'sociopj-capital-' + indexPj"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        {{ formatarNumeroComPonto(valoresSociosPJ.capSoc) }}
      </b-tooltip>
      <div
        class="results sb-1 sb-right sb-top"
        :class="{
          'borda-desativada': !valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-',
        }"
      >
        <Check :valor="valoresSociosPJ.alerta" />
      </div>
      <div
        class="arrowBox"
        @click="alternarDetalhes(indexPj)"
        :class="{
          'btn-disabled': !valoresSociosPJ.cnpj || valoresSociosPJ.cnpj == '-',
        }"
      >
        <span class="material-symbols-outlined icon-arrow" v-if="!arrowControlPj">
          expand_circle_down
        </span>
        <span class="material-symbols-outlined icon-arrow" v-else>
          expand_circle_up
        </span>
      </div>
    </div>
    <div class="secundario" v-if="arrowControlPj">
      <div class="row">
        <div class="cabec sb-2 sb-right sb-top" :id="'BlocoInfoPJSociosPjLinhas-0'">
          Razão Social
        </div>
        <div class="results sb-8 sb-right sb-top">
          {{ formatarNome(valoresSociosPJ.razao) }}
        </div>
      </div>
      <div class="row">
        <div class="cabec sb-2 sb-right sb-top" :id="'BlocoInfoPJSociosPjLinhas-1'">
          Local
        </div>
        <div class="results sb-8 sb-right sb-top">
          {{ valoresSociosPJ.local }}
        </div>
      </div>
    </div>
    <div v-for="(tt, i) in tooltipsInfoPjSociosLinhasFiltrado" :key="i">
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import { userPreferenceStore } from "@/stores/userPreference";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";
import listaTooltipsModelos from "@/helpers/lista_tooltips_modelos.json";

export default {
  name: "BlocoInfoPJSociosLinhas",

  components: {
    Check,
  },

  props: {
    indexPj: Number,
    resultados: Object,
    abreBloco: Boolean,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      result: "valor",
      iconArrow: "/img/superbox/arrow-bottom.png",
      arrowControlPj: false,
      valoresSociosPJ: {},
    };
  },

  watch: {
    resultados: {
      handler() {
        if (Object.keys(this.resultados).length !== 0) {
          this.atualizaValores();
        }
      },
      deep: true,
    },
  },

  methods: {
    selecionaConsultaAdicional(documento) {
      this.abrirSelecaoConsulta(documento, "PJ");
    },

    fechaConsultaAdicional() {
      this.fecharSelecaoConsulta();
    },

    capitalFormatado(valor) {
      if (Math.abs(valor) >= 1000000000) {
        const valorEmBilhoes = (valor / 1000000000).toFixed(1);
        if (parseFloat(valorEmBilhoes) >= 2) {
          return valorEmBilhoes + " bilhões";
        } else {
          return valorEmBilhoes + " bilhão";
        }
      } else if (Math.abs(valor) >= 10000000) {
        const valorEmMilhoes = (valor / 1000000).toFixed(0);
        return valorEmMilhoes + " milhões";
      } else {
        return this.formatarNumeroComPonto(valor);
      }
    },

    formatarCNPJ(cnpj) {
      if (cnpj != null && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          cnpj = cnpj.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }

      return cnpj;
    },

    formatarNumeroComPonto(numero) {
      if (numero && numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    formatarNome(nome) {
      if (nome && nome != "-") {
        if (this.ocultarDados) {
          let palavras = nome.split(" ");

          if (palavras.length > 1) {
            for (let i = 1; i < palavras.length; i++) {
              palavras[i] = palavras[i].replace(/\S/g, "*");
            }
          }
          return palavras.join(" ");
        } else {
          return nome;
        }
      }
      return nome;
    },

    async resetaValores() {
      this.valoresSociosPJ = {};
    },

    atualizaValores() {
      this.valoresSociosPJ = {
        cnpj: this.resultados[`bk_201_004_socio_pj${this.indexPj + 1}_num_cnpj`],
        cnaePrinc: this.resultados[
          `bk_201_004_socio_pj${this.indexPj + 1}_des_cnae_principal`
        ],
        tempoVinc: this.resultados[
          `bk_201_004_socio_pj${this.indexPj + 1}_temp_vinculos_anos`
        ],
        tempoFund: this.resultados[
          `bk_201_004_socio_pj${this.indexPj + 1}_tmp_fundacao_anos`
        ],
        tipo: this.resultados[
          `bk_201_004_socio_pj${this.indexPj + 1}_des_natureza_juridica`
        ],
        capSoc: this.resultados[
          `bk_201_004_socio_pj${this.indexPj + 1}_vlr_capital_social`
        ],
        alerta: this.resultados[
          `bk_201_004_socio_pj${this.indexPj + 1}_flag_situcao_especial`
        ],
        local: this.resultados[`bk_201_004_socio_pj${this.indexPj + 1}_des_local`],
        razao: this.resultados[
          `bk_201_004_socio_pj${this.indexPj + 1}_des_razao_nome_fantasia`
        ],
      };
    },

    alternarDetalhes(i) {
      if (this.arrowControlPj) {
        this.arrowControlPj = false;
      } else {
        this.arrowControlPj = true;
      }
    },

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },
  computed: {
    naturezaJuridicaTratada() {
      if (this.valoresSociosPJ.tipo) {
        const valor = this.valoresSociosPJ.tipo;
        if (valor != "-") {
          if (valor.includes("(")) {
            const match = valor.match(/\((.*?)\)/);
            if (match) {
              return match[1];
            }
          }
        }
        return valor;
      } else {
        return "";
      }
    },

    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    tooltipsInfoPjSociosLinhas() {
      return listaTooltipsModelos.bloco_info_pj_socios_linhas;
    },

    tooltipsInfoPjSociosLinhasFiltrado() {
      if (this.arrowControlPj) {
        return this.tooltipsInfoPjSociosLinhas;
      } else {
        return [];
      }
    },

    tooltipsBloco7Filtrado() {
      if (this.arrowControlPj) {
        return this.tooltipsBloco7;
      } else {
        return [];
      }
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      ocultarDados: "ocultarDados",
    }),
  },

  async mounted() {
    await this.resetaValores();
    this.atualizaValores();
  },
};
</script>

<style lang="scss" scoped>
.linhas {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.principal {
  display: flex;
  width: 100%;
}

.secundario {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 10px 75px;
  padding: 0 0 10px 0;
  border-bottom: dashed 1px #0070c0;
}

.cabec {
  padding: 1px 6px;
  background-color: #d9e1f2;
  color: black;
  border-radius: 5px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  cursor: default;
  line-height: 14px;

  @include flex-center(column);
}
.results {
  height: 40px;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  cursor: default;
  padding: 5px;
  word-break: break-word;
  line-height: 14px;
  text-align: center;

  &.titulo {
    background-color: #d9e1f2;
    border: none;
    border-radius: 5px;
  }

  &.desativado {
    background-color: #d9d9d9;
  }
  &.borda-desativada {
    border: solid 1px #999999 !important;
    color: #999999;
  }
}

.arrowBox {
  width: 20px;
  cursor: pointer;

  @include flex-center(column);

  .icon-arrow {
    width: 20px;
    height: 20px;
    color: #0070c0;
    font-size: 1.3rem;
  }
}

.btn-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5;
}

.text-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.icon-send {
  color: #0070c0;
  width: 24px;
  font-size: 20px;
  cursor: pointer;
  transition: font-size 0.3s;
  line-height: 18px;

  &:hover {
    font-size: 22px;
  }
}

.overlay {
  position: fixed;
  height: 110vh;
  width: 110vw;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 9;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
