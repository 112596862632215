<template>
  <div class="boxSocios">
    <div class="cabecTab">
      <span class="cabecText titulo">
        Informações de Quadro de Sócios e Administadores
      </span>
    </div>
    <div class="tabelapf">
      <div class="linhaCabec">
        <div
          class="cabec"
          v-for="(cab, j) in [
            'Top 05 Sócios PF',
            'CPF',
            'Nome',
            'Tempo Vínculo',
            'Check Sócio Adm.',
          ]"
          :key="cab"
          :class="{
            'sb-1': j == 0 || j == 3 || j == 4,
            'sb-2': j == 1,
            'sb-6': j == 2,
            'sb-right': j != 4,
          }"
          :id="'BlocoInfoPJSociosPf-' + j"
        >
          {{ cab }}
        </div>
      </div>
      <div class="corpo">
        <div class="linhas" v-for="(socioPf, i) in valoresSociosPF" :key="i">
          <div class="principal">
            <div
              class="results titulo sb-1 sb-right sb-top"
              :class="{
                desativado: !socioPf.valido,
              }"
            >
              Sócio PF {{ i + 1 }}
            </div>
            <div
              class="results sb-2 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.valido,
              }"
            >
              <span v-if="!socioPf.valido"> Sem informações </span>
              <span v-else class="text-send">
                {{ formatarCPF(socioPf.cpf) }}
                <span
                  v-if="
                    socioPf.cpf != '-' &&
                    !telaHistorico &&
                    !socioPf.cpf.includes('*') &&
                    !gerandoPdf
                  "
                  class="material-symbols-outlined icon-send"
                  @click="selecionaConsultaAdicional(socioPf.cpf)"
                >
                  send
                </span>
              </span>
            </div>
            <div
              class="results sb-6 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.valido,
              }"
            >
              {{ formatarNome(socioPf.nome) }}
            </div>
            <div
              class="results sb-1 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.valido,
              }"
            >
              {{ socioPf.tempo }}
              <span style="margin-left: 3px" v-if="socioPf.tempo == 1"> ano </span>
              <span
                style="margin-left: 3px"
                v-if="socioPf.tempo != 1 && socioPf.tempo != '-'"
              >
                anos
              </span>
            </div>
            <div
              class="results sb-1 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.valido,
              }"
            >
              <Check :valor="socioPf.checkAdm" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabelapj">
      <div class="linhaCabec">
        <div
          class="cabec verde"
          v-for="(cab, j) in [
            'Top 05 Sócios PJ',
            'CNPJ',
            'CNAE Principal',
            'Tempo Vínculo',
            'Tempo Fundação',
            'Tipo',
            'Capital Social',
            'Alerta de Situação',
          ]"
          :class="{
            'sb-1': j != 2 && j != 1,
            'sb-2': j == 1,
            'sb-3': j == 2,
            'sb-right': j != 7,
            vermel: j == 7,
          }"
          :key="cab"
          :id="'BlocoInfoPJSociosPj-' + j"
        >
          {{ cab }}
        </div>
      </div>
      <div class="corpo">
        <BlocoInfoPJSociosLinhas
          v-for="(item, indexPj) in Array(5)"
          :key="indexPj"
          :indexPj="indexPj"
          :resultados="resultados"
          :abreBloco="abreBloco"
          :exibirTootipsConsultas="exibirTootipsConsultas"
          :gerandoPdf="gerandoPdf"
        />
      </div>
    </div>
    <div v-for="(tt, i) in tooltipsInfoPjSocios" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";

import Check from "@/components/SuperBox/Check.vue";
import BlocoInfoPJSociosLinhas from "@/components/Modelos/BlocoInfoPJSociosLinhas.vue";
import listaTooltipsModelos from "@/helpers/lista_tooltips_modelos.json";

export default {
  name: "BlocoInfoPJSocios",

  components: {
    Check,
    BlocoInfoPJSociosLinhas,
  },

  props: {
    resultados: Object,
    abreBloco: Boolean,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      iconArrow: "/img/superbox/arrow-bottom.png",
      arrowControlPj: new Array(5).fill(false),
      valoresSociosPF: [],
    };
  },

  watch: {
    resultados: {
      handler() {
        if (Object.keys(this.resultados).length !== 0) {
          this.atualizaValores();
        }
      },
      deep: true,
    },
  },

  methods: {
    selecionaConsultaAdicional(documento) {
      this.abrirSelecaoConsulta(documento, "PF");
    },

    fechaConsultaAdicional() {
      this.fecharSelecaoConsulta();
    },

    validaCpf(obj) {
      if (
        (obj.cpf == null || obj.cpf == "-") &&
        (obj.tempo == null || obj.tempo == "-")
      ) {
        obj.valido = false;
      } else {
        obj.valido = true;
      }
    },

    formatarCPF(cpf) {
      if (cpf && cpf !== "-") {
        cpf = cpf.replace(/[^0-9*]/g, "");

        cpf = cpf.replace(
          /^(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,2}|\*{0,2})$/,
          function (_, p1, p2, p3, p4) {
            return `${p1}.${p2}.${p3}-${p4}`;
          }
        );

        if (this.ocultarDados) {
          return cpf.replace(
            /(\d{3}|\*{3})\.(\d{3}|\*{3})\.(\d{3}|\*{3})-(\d{2}|\*{2})/,
            "***.$2.$3-**"
          );
        } else {
          return cpf;
        }
      }
      return cpf;
    },

    formatarNome(nome) {
      if (nome && nome != "-") {
        if (this.ocultarDados) {
          let palavras = nome.split(" ");

          if (palavras.length > 1) {
            for (let i = 1; i < palavras.length; i++) {
              palavras[i] = palavras[i].replace(/\S/g, "*");
            }
          }
          return palavras.join(" ");
        } else {
          return nome;
        }
      }
      return nome;
    },

    alternarDetalhes(i) {
      if (this.arrowControlPj[i]) {
        this.arrowControlPj[i] = false;
      } else {
        this.arrowControlPj[i] = true;
      }
    },

    atualizaValores() {
      this.valoresSociosPF = Array.from({ length: 5 }, (_, index) => ({
        cpf: this.resultados[`bk_201_004_socio_pf${index + 1}_num_cpf`],
        nome: this.resultados[`bk_201_004_socio_pf${index + 1}_des_nomesocio`],
        tempo: this.resultados[`bk_201_004_socio_pf${index + 1}_tmp_vinculo_anos`],
        checkAdm:
          this.resultados[`bk_201_004_socio_pf${index + 1}_flag_socio_admin`] == 1
            ? true
            : this.resultados[`bk_201_004_socio_pf${index + 1}_flag_socio_admin`] == 0
            ? false
            : null,
        valido: false,
      }));

      this.valoresSociosPF.forEach((socio) => {
        this.validaCpf(socio);
      });
    },

    async resetaValoresSocios() {
      this.valoresSociosPF = [];
    },

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  computed: {
    tooltipsInfoPjSocios() {
      return listaTooltipsModelos.bloco_info_pj_socios;
    },

    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      ocultarDados: "ocultarDados",
    }),
  },

  async mounted() {
    await this.resetaValoresSocios();

    this.atualizaValores();
  },
};
</script>

<style lang="scss" scoped>
.boxSocios {
  display: flex;
  flex-direction: column;
  margin: 25px 0 0 0px;
  width: 100%;
  align-items: center;
}

.cabecTab {
  display: flex;
  width: 100%;
  line-height: 14px;
  max-width: 950px;
}

.cabecText {
  display: flex;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  background-color: #2f75b5;
  color: white;
  @include flex-center(column);
  margin: 0 0 10px 0;
}
.tabelapf {
  width: 900px;
  position: relative;
  left: 10px;
}
.tabelapj {
  margin: 30px 0 0 0;
  position: relative;
  left: 10px;
}

.linhaCabec {
  display: flex;
  flex-direction: row;
}

.linhas {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.principal {
  display: flex;
}
.cabec {
  padding: 1px 5px;
  background-color: rgb(55, 69, 129);
  // background-color: #44546a;
  color: white;
  border-radius: 5px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  cursor: default;
  line-height: 16px;

  &.verde {
    background-color: #18817e;
  }
  &.vermel {
    background-color: #d65532;
  }
  @include flex-center(column);
}
.results {
  height: 40px;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  cursor: default;
  padding: 5px;
  word-break: break-word;
  line-height: 14px;
  text-align: center;

  &.titulo {
    background-color: #d9e1f2;
    border: none;
    border-radius: 5px;
  }
  &.desativado {
    background-color: #d9d9d9 !important;
  }
  &.borda-desativada {
    border: solid 1px #999999 !important;
    color: #999999;
  }
  &.index0 {
    // color: #44546a;
    background-color: #44546a;
    color: white;
    font-weight: 600;
  }
}

.text-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.icon-send {
  color: #0070c0;
  width: 24px;
  font-size: 20px;
  cursor: pointer;
  transition: font-size 0.3s;
  line-height: 18px;

  &:hover {
    font-size: 22px;
  }
}

.disabled-title {
  background-color: #b9b9b9 !important;
  color: white;
}

.disabled-border {
  color: #a7a7a7;
  border: solid 1px #a7a7a7;
}

.overlay {
  position: fixed;
  height: 110vh;
  width: 110vw;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  z-index: 9;
  left: 0;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
